import React from "react"
import Layout from "../layouts"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"
import Features from "../components/features"

const PageError = () => {
  return (
    <Layout>
      <div className="page">
        <Header />
        <PageTitle pageTitle={`404 Not found`} />

        <section className="section sectionWhite">
          <div className="containerFluid">
            <div className="row">
              <div className="col-bp1-12 col-bp4-8 col-bp6-7">
                <div className="entry">
                  <p>404</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section sectionWhite section--close-btm">
          <div className="features">
            <div className="containerFluid">
              <div className="row">
                <div className="col-bp1-12">
                  <Features />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default PageError
