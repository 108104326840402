import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Brand from "./logos/brand"
import Nav from "../components/nav"

const Header = props => (
  <StaticQuery
    query={graphql`
      query {
        allWpMenu(filter: { slug: { eq: "primary-menu" } }) {
          edges {
            node {
              name
              menuItems {
                nodes {
                  label
                  path
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <header className="siteHeader">
        <div className="containerFluid">
          <div className="row">
            <div className="col-bp1-12 col-bp4-3">
              <Brand />
            </div>
            <div className="col-bp1-12 col-bp4-9">
              <Nav nav={data.allWpMenu.edges} />
            </div>
          </div>
        </div>
      </header>
    )}
  />
)

export default Header
